'use client'

import { useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { Container, Grid, Typography, Paper } from '@mui/material'
import { Google as GoogleIcon } from '@mui/icons-material'

// Types
import type { LoginProps } from '@/types/components/pages/login'
import type { ImageProps } from '@/types/components/atoms/shapes/image'

// Configurations
import Config from '@/config'
import APIConfig from '@/config/api'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'
import { redirect } from 'next/navigation'

// Components
const SlideshowMolecule = dynamic(() => import('@/components/molecules/slideshow'))
const LogoAtom = dynamic(() => import('@/components/atoms/shapes/logo'))
const OutlinedButtonAtom = dynamic(() => import('@/components/atoms/buttons/text/outlined'))

const LoginPage = (props: LoginProps) => {
	// Props
	const { lng } = props

	// Variables
	const { t } = useTranslation(lng)
	const backgroundRef = useRef<HTMLDivElement | null>(null)
	const [checkCookie, setCheckCookie] = useState<boolean>(false)

	const images: ImageProps[] = [
		{
			width: 667,
			height: 1000,
			src: '/images/slideshow/autumn-person-with-cozy-clothes.jpg',
			alt: 'autumn person with cozy clothes',
			color: '#beaa89'
		},
		{
			width: 667,
			height: 1000,
			src: '/images/slideshow/cheerful-guy-enjoying-outdoor-coffee-break.jpg',
			alt: 'cheerful guy enjoying outdoor coffee break',
			color: '#c7d7e7'
		},
		{
			width: 664,
			height: 1000,
			src: '/images/slideshow/confident-cheerful-young-businesswoman.jpg',
			alt: 'confident cheerful young businesswoman',
			color: '#1a1a29'
		},
		{ width: 667, height: 1000, src: '/images/slideshow/happy-young-african-man.jpg', alt: 'happy young african man', color: '#ebe8e7' },
		{
			width: 667,
			height: 1000,
			src: '/images/slideshow/beautiful-happy-african-american-woman-smiling-cheerfully-keeping-her-arms-folded.jpg',
			alt: 'beautiful happy african american woman smiling cheerfully keeping her arms folded',
			color: '#ddb49c'
		},
		{
			width: 667,
			height: 1000,
			src: '/images/slideshow/man-green-shirt-having-his-arms-crossed.jpg',
			alt: 'man green shirt having his arms crossed',
			color: '#919f57'
		},
		{ width: 667, height: 1000, src: '/images/slideshow/man-with-his-arms-crossed.jpg', alt: 'man with his arms crossed', color: '#33546c' },
		{
			width: 667,
			height: 1000,
			src: '/images/slideshow/portrait-beautiful-girl-white-background.jpg',
			alt: 'portrait beautiful girl white background',
			color: '#ca320f'
		},
		{
			width: 667,
			height: 1000,
			src: '/images/slideshow/smiling-handsome-entrepreneur-looking-camera.jpg',
			alt: 'smiling handsome entrepreneur looking camera',
			color: '#bbc1cd'
		},
		{
			width: 667,
			height: 1000,
			src: '/images/slideshow/young-woman-standing-with-folded-arms.jpg',
			alt: 'young woman standing with folded arms',
			color: '#9d7960'
		}
	]

	// Callbacks
	useEffect(() => {
		if (!checkCookie) {
			const token = Cookies.get(`${Config.shortName}-token`)
			const accessAdmin = Cookies.get(`${Config.shortName}-access-admin`)

			setCheckCookie(true)

			// Check user is logged in
			if (token) {
				if (accessAdmin === '1' || accessAdmin === 'true') redirect(`/${lng}`)
				else if (accessAdmin === '0' || accessAdmin === 'false') redirect(`/${lng}/access-denied`)
			}
		}
	}, [lng, checkCookie])

	return (
		<Grid ref={backgroundRef} width={1} py="10vh" bgcolor="lightblue" sx={{ transition: 'background-color .5s ease' }}>
			<Container maxWidth="lg">
				<Paper elevation={6} sx={{ borderRadius: 2, overflow: 'hidden' }}>
					<Grid container height="80vh">
						<Grid display={{ xs: 'none', sm: 'block' }} size={{ sm: 6, md: 4 }} height={1}>
							<SlideshowMolecule backgroundRef={backgroundRef} images={images} showSelectedNumber={4} />
						</Grid>

						<Grid size={{ xs: 12, sm: 6, md: 8 }} px={{ xs: 2.5, sm: 'inherit' }} pl={{ sm: 1 }}>
							<Grid container direction="column" justifyContent="center" alignItems="center" spacing={4} height={1}>
								<Grid>
									<LogoAtom width={50} height={50} sx={{ borderRadius: 10 }} />
								</Grid>

								<Grid px={2} textAlign="center">
									<Typography variant="h3">{t('common:title.welcome')}</Typography>
									<Typography variant="body1">{t('common:subtitle.welcome')}</Typography>
								</Grid>

								<Grid>
									<Link href={`${APIConfig.url}/user/google/redirect?redirect_to=dashboard`}>
										<OutlinedButtonAtom lng={lng} text="common:button.google" startIcon={<GoogleIcon />} />
									</Link>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</Grid>
	)
}

export default LoginPage
