// Types
import type { APIProps } from '@/types/config/api'

const APIConfig: APIProps = {
	url: process.env.API_URL || 'http://localhost:8000/api',
	version: process.env.API_VERSION || '1.0.0',
	revalidate: 0
}

export default APIConfig
